import { useTranslation } from "react-i18next";
import Section2 from "../home/section-2";
import Section4 from "../home/section-4";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import Process from "./Process";
import Testimonials from "./Testimonials";
import Benefits from "./Benefits";
import Section1 from "./section-1";
import Footer from "../home/footer";
import FormContact from "../Contact Us/form-contact";
import { Helmet } from "react-helmet-async";
import GetStarted from "../home/get-started";


const Enhance: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };


    return (
        <>
            <Helmet>
                <title> {t("Enhance")}</title>
                <meta name="description" content={t("In today's competitive real estate market")} />
            </Helmet>

            <header id="header" className="header page-generate page-enhance page-top-80">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="text-container">
                                <div className="section-butt  d-flex justify-content-center">
                                    <span className="section-title-AI ">
                                        <svg width="24" height="25" className="marg10" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_135_2592" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                                                <path d="M24 0.679688H0V24.6797H24V0.679688Z" fill="white" />
                                            </mask>
                                            <g mask="url(#mask0_135_2592)">
                                                <path d="M8 16.6797C8 17.2319 8.44771 17.6797 9 17.6797C9.55229 17.6797 10 17.2319 10 16.6797C10 14.3713 10.5108 12.9373 11.3842 12.0639C12.2575 11.1905 13.6915 10.6797 16 10.6797C16.5522 10.6797 16.9999 10.232 16.9999 9.67969C16.9999 9.12741 16.5522 8.67969 16 8.67969C13.6915 8.67969 12.2575 8.16887 11.3842 7.2955C10.5108 6.42212 10 4.98818 10 2.67969C10 2.1274 9.55229 1.67969 9 1.67969C8.44771 1.67969 8 2.1274 8 2.67969C8 4.98818 7.48919 6.42212 6.6158 7.2955C5.74243 8.16887 4.30848 8.67969 2 8.67969C1.44772 8.67969 1 9.12741 1 9.67969C1 10.232 1.44772 10.6797 2 10.6797C4.30848 10.6797 5.74243 11.1905 6.6158 12.0639C7.48919 12.9373 8 14.3713 8 16.6797Z" fill="#573CFF" />
                                                <path d="M16.5 22.6796C16.5 23.232 16.9477 23.6797 17.5 23.6797C18.0523 23.6797 18.5 23.232 18.5 22.6796C18.5 21.2393 18.8198 20.4304 19.2852 19.9649C19.7507 19.4995 20.5596 19.1797 22 19.1797C22.5523 19.1797 23 18.732 23 18.1796C23 17.6274 22.5523 17.1797 22 17.1797C20.5596 17.1797 19.7507 16.8599 19.2852 16.3944C18.8198 15.929 18.5 15.1201 18.5 13.6796C18.5 13.1274 18.0523 12.6797 17.5 12.6797C16.9477 12.6797 16.5 13.1274 16.5 13.6796C16.5 15.1201 16.1802 15.929 15.7147 16.3944C15.2494 16.8599 14.4404 17.1797 13 17.1797C12.4477 17.1797 12 17.6274 12 18.1796C12 18.732 12.4477 19.1797 13 19.1797C14.4404 19.1797 15.2494 19.4995 15.7147 19.9649C16.1802 20.4304 16.5 21.2393 16.5 22.6796Z" fill="#573CFF" />
                                            </g>
                                        </svg>
                                        {t("Enhance")}
                                    </span>
                                </div>
                                <h1 className="h1-large text-center">{t("Transform Your Property Listings")}</h1>
                                <h2 className="enh-h2">{t("The Complete Solution for Exceptional")}</h2>
                                <p className="p-large text-center">{t("In today's competitive real estate market")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12 d-flex justify-content-center ">
                            <img className="img-g" src={getImagePath("Group-1410087827.png")} alt="alternative" />
                            <img className="img-g-mobile" src={getImagePath("Group-1410087827.png")} alt="alternative" />
                        </div>
                    </div>
                </div>
            </header>

            {/* Real estate agents and agencies rely on Coralytics*/}
            <Section2 />

            {/* Why Choose Coralytics? */}
            <Section1 />

            {/* Key Features That Set You Apart */}
            <div className="basic-Benefits marg-2rem">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1">{t("Key Features That Set")}<span> {t("You Apart")} </span> </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/Frame 2147224988.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Watermark Removal")} </div>
                                    <p className="testimonial-text"> {t("Eliminate distracting watermarks")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/Frame 2147224986.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Sky Replacement")} </div>
                                    <p className="testimonial-text"> {t("Swap out dull skies with vibrant")} </p>

                                </div>
                            </div></div>
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body flex-grow-1">
                                    <img className="quotes" src="/assets/images/Frame 2147224934.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Item Removal")} </div>
                                    <p className="testimonial-text"> {t("Stage a property virtually in real-time")} </p>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Section4 />

            {/* Benefits */}
            <Benefits />

            {/* Process */}
            <Process />

            {/* Testimonials */}
            <Testimonials />

            {/*Get Started with Coralytics Today */}
            <div className='basic-12 marg-2rem back-basic-12'>
                <div className="container">
                    <div className="card">
                        <div className='row d-flex justify-content-center align-items-center align-self-center'>
                            <div className='col-lg-12 col-xl-12 '>
                                <h1> {t("Get Started with Coralytics Today")} </h1>
                                <p> {t("Don't settle for ordinary listings")}</p>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-auto col-12 d-flex justify-content-center mb-2 mb-md-0">
                                        <GetStarted Style="btn-solid-lg" Value={`${t("Request Free Demo")}`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </>
    )

}
export default Enhance;