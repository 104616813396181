import { useTranslation } from "react-i18next";


const AI_Generated: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();


    return (
        <>
            {/*AI-Generated Descriptions and Titles*/}
            <div className="basic-1 marg-2rem">
                <img className="decoration-circles-5-1" src="/assets/images/Group 54.svg" alt="alternative" />
                <div className="container padd-50 padd-20-bottom">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-6 card-order-2 mb-3">
                            <div className="image-container">
                                <img className="img-fluid" src="/assets/images/ai-generate-desc.png" alt="alternative" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 card-order-1 mb-3 ">
                            <div className="text-container">
                                <div className="section-butt">
                                    <span className="section-title">
                                        <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                            <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                        </svg>{t("AI-Generated Descriptions and Titles")}
                                    </span>
                                </div>
                                <h2>{t("Compelling, Accurate and Engaging")}</h2>
                                <p>{t("Craft the perfect property description and title")}</p>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        
        </>

    )

}
export default AI_Generated;