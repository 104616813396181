import './App.css';
import PublicRouter from './Router/PublicRouter';
import { HelmetProvider } from 'react-helmet-async';

const App: React.FunctionComponent<{}> = (props) => {

  return (
    <HelmetProvider>
      <PublicRouter />
    </HelmetProvider>
  );
}

export default App;
