import { useTranslation } from "react-i18next";



const Benefits: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };

    return (
        <>


            <div className="basic-Benefits marg-2rem">
                <div className="container padd-50">
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <div className="section-butt">
                                <span className="section-title">
                                    <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                        <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                    </svg>{t("Benefits")}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1" style={{ margin: "10px 0px 20px 0px" }}>{t("How Real Estate Developers Benefit from")}<span> {t("Coralytics")} </span> </h2>
                            <h5 className="h5-heading-1">{t("Showcase Properties with")}<span> {t("Stunning Visuals")}</span></h5>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-4 mb-3">
                            <div className="card height-devop">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-4.png" alt="alternative" />
                                    <div className="testimonial-author "> {t("Advanced Photo Enhancements")} </div>
                                    <p className="testimonial-text"> {t("Enhance property images automatically—adjust")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-devop">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-5.png" alt="alternative" />
                                    <div className="testimonial-author "> {t("AI Room Staging")} </div>
                                    <p className="testimonial-text"> {t("Virtually furnish and decorate empty")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-devop">
                                <div className="card-body flex-grow-1">
                                    <img className="quotes" src="/assets/images/benefit-6.png" alt="alternative" />
                                    <div className="testimonial-author "> {t("AI Garden Landscaping")} </div>
                                    <p className="testimonial-text"> {t("Transform outdoor areas with virtual")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-devop">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-7.png" alt="alternative" />
                                    <div className="testimonial-author "> {t("2D to 3D Floor Plan Conversion")} </div>
                                    <p className="testimonial-text"> {t("Convert flat floor plans into immersive")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-devop">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-8.png" alt="alternative" />
                                    <div className="testimonial-author "> {t("Sky Replacement")} </div>
                                    <p className="testimonial-text"> {t("Replace overcast skies with vibrant")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="basic-Benefits ">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1">{t("Enhance Marketing Efficiency")}<span> {t("and Impact")} </span> </h2>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-4 mb-3">
                            <div className="card height-devop">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-9.png" alt="alternative" />
                                    <div className="testimonial-author "> {t("Image Quality and Compliance Analysis")} </div>
                                    <p className="testimonial-text"> {t("Automatically assess and improve")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-devop">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-10.png" alt="alternative" />
                                    <div className="testimonial-author "> {t("Image Tags for Enhanced SEO")} </div>
                                    <p className="testimonial-text"> {t("Generate relevant keywords and tags for your")} </p>

                                </div>
                            </div></div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-devop">
                                <div className="card-body flex-grow-1">
                                    <img className="quotes" src="/assets/images/benefit-11.png" alt="alternative" />
                                    <div className="testimonial-author "> {t("Optimized Image Positioning")} </div>
                                    <p className="testimonial-text"> {t("Arrange images strategically to create")} </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="basic-Benefits ">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1">{t("Streamline Operations with")}<span> {t("Automation")} </span> </h2>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-4 mb-3">
                            <div className="card height-document">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-12.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Document Information Extraction")} </div>
                                    <p className="testimonial-text"> {t("Automate data extraction from contracts")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-document">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-13.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Passport and ID Recognition")} </div>
                                    <p className="testimonial-text"> {t("Securely extract and verify")} </p>
                                </div>
                            </div></div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-document">
                                <div className="card-body flex-grow-1">
                                    <img className="quotes" src="/assets/images/benefit-14.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("API Integration")} </div>
                                    <p className="testimonial-text"> {t("Seamlessly integrate Coralytics features into")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="basic-Benefits ">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1">{t("Gain Deep Insights with")}<span> {t("Data Analysis")} </span> </h2>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-4 mb-3">
                            <div className="card height-document">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-15.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Image Analysis")} </div>
                                    <p className="testimonial-text"> {t("Gain insights into image quality scores")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-document">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-16.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Text Analysis")} </div>
                                    <p className="testimonial-text"> {t("Analyze client feedback, reviews, and surveys")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="basic-Benefits ">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1">{t("Protect Your Brand and")}<span> {t("Build Trust")} </span> </h2>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-4 mb-3">
                            <div className="card ">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-17.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Content Moderation")} </div>
                                    <p className="testimonial-text"> {t("Automatically detect and remove inappropriate")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card ">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/benefit-18.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Compliance Checks")} </div>
                                    <p className="testimonial-text"> {t("Identify and address issues like NSFW content")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}
export default Benefits;