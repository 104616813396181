import { useTranslation } from "react-i18next";
import Section2 from "./section-2";
import Section3 from "./section-3";
import Section5 from "./section-5";
import Section7 from "./section-7";
import Partners from "./partners";
import AIListing from "./AI-listing";
import Section4 from "./section-4";
import Footer from "./footer";
import FormContact from "../Contact Us/form-contact";
import { Helmet } from "react-helmet-async";
import GetStarted from "./get-started";
import Blogs from "./blogs";

const Home: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };

    return (
        <>
            <Helmet>
                <title>{t("Coralytics")}</title>
                <meta name="description" content={t("Over 80 AI capabilities")} />
            </Helmet>

            {/* section 1 */}
            <header id="header" className="header  page-home dir-rtl ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-container">
                                <h1 className="h1-large">{t("the-ultimate-AI-marketing")}</h1>
                                <p className="p-large">{t("Over 80 AI capabilities")}</p>
                                <GetStarted Style={"btn-solid-lg"} Value={`${t("Get Started")}`} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image-container header-web padd-20">
                                <img className="img-fluid" src={"/assets/images/Frame-2147223465.png"} alt="alternative" />
                            </div>
                            <div className="image-container header-moblie">
                                <img className="img-fluid-1" src={"/assets/images/Frame 2147224739.png"} loading="lazy" alt="alternative" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* Real estate agents and agencies rely on Coralytics*/}
            <Section2 />

            <Partners />

            {/* AI Listing Creation */}
            <AIListing />

            <Section4 />

            {/*Generate & Enhance*/}
            <Section3 />

            {/* Understand & Moderate */}
            <Section5 />

            {/* Coralytics is for */}
            <div className="cards-01 marg-2rem bg-green">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading"> {t("Coralytics is for")}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/image-1.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Real Estate Agencies")} </div>
                                    <p className="testimonial-text"> {t("Improve your listing quality")} </p>
                                    <div className="card-learn d-flex justify-content-center">
                                        <a className="learn_more" href="/Real-Estate-Agencies">{t("Learn More")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/image-2.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Marketplaces")} </div>
                                    <p className="testimonial-text"> {t("Automate more than 90% of your content")} </p>
                                    <div className="card-learn d-flex justify-content-center">
                                        <a className="learn_more" href="/Marketplace">{t("Learn More")}</a>
                                    </div>
                                </div>
                            </div></div>
                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body flex-grow-1">
                                    <img className="quotes" src="/assets/images/image-3.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Real Estate Developers")} </div>
                                    <p className="testimonial-text"> {t("Protect your brand and increase")} </p>
                                    <div className="card-learn d-flex justify-content-center">
                                        <a className="learn_more" href="/Developers">{t("Learn More")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/image-4.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Interior Designers")} </div>
                                    <p className="testimonial-text"> {t("Improve quality and compliance standards")} </p>
                                    <div className="card-learn d-flex justify-content-center">
                                        <a className="learn_more" href="/Interior-Designers">{t("Learn More")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Blogs*/}
           {/* <Blogs />  */}

            {/*Boost your return on marketing */}
            <Section7 />

            <Footer />

        </>
    )

}
export default Home;