import { useTranslation } from "react-i18next";
import Section2 from "../home/section-2";
import AI_Generated from "../AI Listing/AI-Generated";
import Section4 from "../home/section-4";
import Section7 from "../home/section-7";
import Questions from "../AI Listing/Questions";
import Testimonials from "../AI Listing/Testimonials";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";
import ListingVideo from "./Listing Video";
import Footer from "../home/footer";
import FormContact from "../Contact Us/form-contact";
import { Helmet } from "react-helmet-async";


const Generate: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };

    return (
        <>
            <Helmet>
                <title>{t("Generate")}</title>
                <meta name="description" content={t("Tailored visual content designed")} />
            </Helmet>

            <header id="header" className="header page-generate page-top-80 page-mobile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="text-container">
                                <div className="section-butt  d-flex justify-content-center">
                                    <span className="section-title-AI ">
                                        <svg width="24" height="25" className="marg10" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_135_2592" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                                                <path d="M24 0.679688H0V24.6797H24V0.679688Z" fill="white" />
                                            </mask>
                                            <g mask="url(#mask0_135_2592)">
                                                <path d="M8 16.6797C8 17.2319 8.44771 17.6797 9 17.6797C9.55229 17.6797 10 17.2319 10 16.6797C10 14.3713 10.5108 12.9373 11.3842 12.0639C12.2575 11.1905 13.6915 10.6797 16 10.6797C16.5522 10.6797 16.9999 10.232 16.9999 9.67969C16.9999 9.12741 16.5522 8.67969 16 8.67969C13.6915 8.67969 12.2575 8.16887 11.3842 7.2955C10.5108 6.42212 10 4.98818 10 2.67969C10 2.1274 9.55229 1.67969 9 1.67969C8.44771 1.67969 8 2.1274 8 2.67969C8 4.98818 7.48919 6.42212 6.6158 7.2955C5.74243 8.16887 4.30848 8.67969 2 8.67969C1.44772 8.67969 1 9.12741 1 9.67969C1 10.232 1.44772 10.6797 2 10.6797C4.30848 10.6797 5.74243 11.1905 6.6158 12.0639C7.48919 12.9373 8 14.3713 8 16.6797Z" fill="#573CFF" />
                                                <path d="M16.5 22.6796C16.5 23.232 16.9477 23.6797 17.5 23.6797C18.0523 23.6797 18.5 23.232 18.5 22.6796C18.5 21.2393 18.8198 20.4304 19.2852 19.9649C19.7507 19.4995 20.5596 19.1797 22 19.1797C22.5523 19.1797 23 18.732 23 18.1796C23 17.6274 22.5523 17.1797 22 17.1797C20.5596 17.1797 19.7507 16.8599 19.2852 16.3944C18.8198 15.929 18.5 15.1201 18.5 13.6796C18.5 13.1274 18.0523 12.6797 17.5 12.6797C16.9477 12.6797 16.5 13.1274 16.5 13.6796C16.5 15.1201 16.1802 15.929 15.7147 16.3944C15.2494 16.8599 14.4404 17.1797 13 17.1797C12.4477 17.1797 12 17.6274 12 18.1796C12 18.732 12.4477 19.1797 13 19.1797C14.4404 19.1797 15.2494 19.4995 15.7147 19.9649C16.1802 20.4304 16.5 21.2393 16.5 22.6796Z" fill="#573CFF" />
                                            </g>
                                        </svg>
                                        {t("Generate")}
                                    </span>
                                </div>
                                <h1 className="h1-large text-center">{t("Create Stunning Visuals to Elevate")}</h1>
                                <p className="p-large text-center">{t("Tailored visual content designed")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12 d-flex justify-content-center ">
                            <img className="img-g" src={getImagePath("generate-img.png")} alt="alternative" />
                            <img className="img-g-mobile" src={getImagePath("generate-img.png")} alt="alternative" />
                        </div>
                    </div>
                </div>
            </header>

            {/* Real estate agents and agencies rely on Coralytics*/}
            <Section2 />

            {/* AI-Generated Descriptions and Titles / Virtual Staging*/}
            <AI_Generated />

            {/*Virtual Staging */}
            <div className="basic-1 marg-2rem">
                <img className="decoration-circles-5-1" src="/assets/images/Group 54.svg" alt="alternative" />
                <div className="container padd-50 padd-20-bottom">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-xl-6 card-order-2 mb-3">
                            <div className="image-container">
                                <img className="img-fluid" src={getImagePath("staging.png")} alt="alternative" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6 card-order-1 mb-3 ">
                            <div className="text-container">
                                <div className="section-butt">
                                    <span className="section-title">
                                        <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                            <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                        </svg>{t("Virtual Staging")}
                                    </span>
                                </div>
                                <h2>{t("Transform rooms in seconds")}</h2>
                                <p>{t("Instantly elevate your property's interior")}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Section4 />

            {/* Virtual Staging and Listing Video  and  3D Floor plans*/}
            <ListingVideo />

            <Testimonials />

            <Questions />

            {/*Boost your return on marketing */}
            <Section7 />

            <Footer />


        </>
    )

}
export default Generate;