import { useTranslation } from "react-i18next";
import { useState } from "react";
import { SnackbarCloseReason, Snackbar, Alert } from "@mui/material";
import { Contact } from "../../services/serv";

const ContactSection: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phone, setPhone] = useState('');
    const [messageValue, setMessageValue] = useState('');


    const [open, setOpen] = useState<any>(false);
    const [color, setColor] = useState<any>();
    const [message, setMessage] = useState<any>();
    const [error, setError] = useState('');
    const [formError, setFormError] = useState({
        name: false,
        email: false,
        companyName: false,
        phone: false,
        message: false
    });

    const validateEmail = (email: any) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
    
        resetFormErrors();
    
        const isValid = validateForm();
        if (!isValid) return; 
    
        submitForm();
    };
    
    const resetFormErrors = () => {
        setFormError({
            name: false,
            email: false,
            companyName: false,
            phone: false,
            message: false
        });
    };
    
    const validateForm = () => {
        let valid = true;
    
        if (!name) {
            setFormError((prevState) => ({ ...prevState, name: true }));
            valid = false;
        }
    
        if (!email) {
            setFormError((prevState) => ({ ...prevState, email: true }));
            valid = false;
        } else if (!validateEmail(email)) {
            setError('Invalid email address');
            valid = false;
        }
    
        if (!companyName) {
            setFormError((prevState) => ({ ...prevState, companyName: true }));
            valid = false;
        }
    
        if (!phone) {
            setFormError((prevState) => ({ ...prevState, phone: true }));
            valid = false;
        }
    
        if (!message) {
            setFormError((prevState) => ({ ...prevState, messageValue: true }));
            valid = false;
        }
    
        return valid;
    };
    
    const submitForm = () => {
        setError(''); 
        Contact({ name, company_email: email, message: messageValue })
            .then((resp) => {
                handleSuccess();
            })
            .catch((error) => {
                handleError();
            });
    };
    
    const handleSuccess = () => {
        setColor("success");
        setOpen(true);
        setMessage("Congratulations. Your message has been sent successfully");
    };
    
    const handleError = () => {
        setColor("error");
        setOpen(true);
        setMessage("Error, please retry. Your message has not been sent");
    };

    return (
        <>
            <div className="section-contact">
                <div className="back-contact">
                    <div className="row d-flex align-items-center" >
                        <div className="col-md-6">
                            <div className="image-container marg-30">
                                <img className="img none-mobile" src="/assets/images/design-5ba368e8-13.png" alt="alternative" />
                            </div>
                        </div>
                        <div className="col-md-6 dir-rtl">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="title-h4 mb-3">{t("Get In Touch")}</h4>
                                    <form>
                                        <div className="row">
                                            <div className="col-6 form-group mb-3">
                                                <label htmlFor="formGroupExampleInput" className="form-label">{t("Name")}</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1"><svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.81871 6.53587C8.52215 6.53587 9.90305 5.15496 9.90305 3.45153C9.90305 1.74809 8.52215 0.367188 6.81871 0.367188C5.11528 0.367188 3.73438 1.74809 3.73438 3.45153C3.73438 5.15496 5.11528 6.53587 6.81871 6.53587Z" fill="#7D7AFF" />
                                                        <path d="M12.9897 12.3175C12.9897 14.2337 12.9897 15.7874 6.82102 15.7874C0.652344 15.7874 0.652344 14.2337 0.652344 12.3175C0.652344 10.4014 3.41437 8.84766 6.82102 8.84766C10.2277 8.84766 12.9897 10.4014 12.9897 12.3175Z" fill="#7D7AFF" />
                                                    </svg>
                                                    </span>
                                                    <input type="text"  className={`form-control input ${formError.name ? 'error-border' : ''}`} placeholder={t("Full Name")} aria-label="Username" aria-describedby="basic-addon1" value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                </div>
                                                {formError.name && <p className="error">{t("Name is required")}</p>}
                                            </div>
                                            <div className="col-6 form-group mb-3">
                                                <label htmlFor="formGroupExampleInput" className="form-label">{t("Phone Number")}</label>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1"><svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.67023 8.39738C6.7806 10.5795 8.56951 12.3685 10.7517 13.4788L12.4481 11.7824C12.664 11.5665 12.9647 11.5048 13.2346 11.5897C14.0982 11.875 15.0235 12.0292 15.9874 12.0292C16.1919 12.0292 16.388 12.1104 16.5326 12.255C16.6772 12.3996 16.7584 12.5958 16.7584 12.8003V15.4991C16.7584 15.7036 16.6772 15.8997 16.5326 16.0443C16.388 16.1889 16.1919 16.2702 15.9874 16.2702C12.5108 16.2702 9.17659 14.8891 6.71828 12.4308C4.25997 9.97247 2.87891 6.63829 2.87891 3.16171C2.87891 2.95721 2.96015 2.76108 3.10475 2.61647C3.24936 2.47186 3.44549 2.39063 3.64999 2.39062H6.34879C6.55329 2.39063 6.74942 2.47186 6.89403 2.61647C7.03863 2.76108 7.11987 2.95721 7.11987 3.16171C7.11987 4.12557 7.27409 5.05087 7.55939 5.91448C7.64421 6.18436 7.58252 6.48509 7.36662 6.70099L5.67023 8.39738Z" fill="#7D7AFF" />
                                                    </svg>
                                                    </span>
                                                    <input type="phone"  className={`form-control input ${formError.phone ? 'error-border' : ''}`} placeholder={t("Phone Number")} aria-label="Username" aria-describedby="basic-addon1" value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                    />
                                                </div>
                                                {formError.phone && <p className="error">{t("Phone number is required")}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-12 form-group  mb-3">
                                            <label htmlFor="formGroupExampleInput" className="form-label">{t("Company Name")}</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1"><i className="fa-solid fa-building"></i>
                                                </span>
                                                <input type="text"  className={`form-control input ${formError.companyName ? 'error-border' : ''}`} placeholder={t("Company Name")} aria-label="Username" aria-describedby="basic-addon1" value={companyName}
                                                    onChange={(e) => setCompanyName(e.target.value)}
                                                />
                                            </div>
                                            {formError.companyName && <p className="error">{t("Company Name is required")}</p>}
                                        </div>
                                        <div className="col-md-12 form-group mb-3">
                                            <label htmlFor="formGroupExampleInput" className="form-label">{t("Email Address")}</label>
                                            <div className="input-group">
                                                <span className="input-group-text" id="basic-addon1">
                                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.9889 6.50231L9.82022 10.3577L3.65154 6.50231V4.96014L9.82022 8.81556L15.9889 4.96014M15.9889 3.41797H3.65154C2.79564 3.41797 2.10938 4.10423 2.10938 4.96014V14.2132C2.10938 14.6222 2.27185 15.0144 2.56107 15.3036C2.85028 15.5928 3.24254 15.7553 3.65154 15.7553H15.9889C16.3979 15.7553 16.7902 15.5928 17.0794 15.3036C17.3686 15.0144 17.5311 14.6222 17.5311 14.2132V4.96014C17.5311 4.55113 17.3686 4.15887 17.0794 3.86966C16.7902 3.58045 16.3979 3.41797 15.9889 3.41797Z" fill="#7D7AFF" />
                                                    </svg>
                                                </span>
                                                <input type="email"    className={`form-control input ${formError.email ? 'error-border' : ''}`} placeholder={t("Email Address")} aria-label="Username" aria-describedby="basic-addon1" value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            {formError.email && <p className="error">{t("Email is required")}</p>}
                                            {error && <p className="error">{error}</p>}
                                        </div>
                                        <div className="col-md-12 form-group mb-3">
                                            <label htmlFor="formGroupExampleInput" className="form-label">{t("Message")}</label>
                                            <textarea  className={`form-control textarea ${formError.message ? 'error-border' : ''}`}  placeholder={t("Type here...")} aria-label="Username" aria-describedby="basic-addon1" value={messageValue}
                                                onChange={(e) => setMessageValue(e.target.value)}
                                            />
                                            {formError.message && <p className="error">{t("Message is required")}</p>}
                                        </div>
                                        <button className="button"  onClick={handleSubmit}>{t("Send Message")}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={color}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default ContactSection;