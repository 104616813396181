
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";

const Partners: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();

    return (<></>
        // <div className="cards-2 card-marquee">

        //     <div className="row">
        //         <div className="col-lg-12">
        //             <h2 className="h2-heading-1">{t("Trusted by Top")} <span> {t("Real Estate Companies")} </span> </h2>
        //         </div>
        //     </div> 
        //     <div className="marquee ">
        //         <Marquee pauseOnClick={true}>
        //             <div className="owl-item"><img src="/assets/images/mccone logo.png" alt="" /></div>
        //             <div className="owl-item"><img src="/assets/images/logo Provident.png" alt="" /></div>
        //             <div className="owl-item"><img src="/assets/images/Vector.png" alt="" /></div>
        //             <div className="owl-item"><img src="/assets/images/new-gradient-logo thinkprop.png" width="280px" alt="" /></div>
        //             <div className="owl-item"><img src="/assets/images/Metropolitan logo.png" alt="" /></div>
        //             <div className="owl-item"><img src="/assets/images/Innovation expert real estate institute logo.png" alt="" /></div>

        //         </Marquee>
        //     </div> 
        // </div>
    )
}
export default Partners;