import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NewsletterServ } from "../../services/serv";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Footer: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();




    return (<>

        <div className="basic-8">
            <div className="container">

                <div className="row footer-web">
                    <div className="col-md-4 footer-image">
                        <img src="/assets/images/Coralytics trans.png" alt="" />
                        <div className="d-flex mt-svg ">
                            <a href="https://www.linkedin.com/company/coralyticsinc/" title="coralytrics" target="_blank" rel="coralytrics">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="white" />
                                    <path d="M18.1973 17.0009C18.197 17.5313 17.9756 18.0399 17.5819 18.4147C17.1881 18.7896 16.6542 19 16.0976 18.9998C15.541 18.9995 15.0073 18.7886 14.6139 18.4133C14.2206 18.0381 13.9997 17.5293 14 16.9989C14.0003 16.4685 14.2217 15.9599 14.6154 15.585C15.0092 15.2102 15.5431 14.9997 16.0997 15C16.6563 15.0003 17.19 15.2112 17.5833 15.5865C17.9767 15.9617 18.1976 16.4705 18.1973 17.0009ZM18.2602 20.4807H14.063V33H18.2602V20.4807ZM24.8919 20.4807H20.7156V33H24.8499V26.4304C24.8499 22.7706 29.8552 22.4306 29.8552 26.4304V33H34V25.0704C34 18.9008 26.5918 19.1308 24.8499 22.1606L24.8919 20.4807Z" fill="white" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-2 text-left footer-center">
                        <p className="mb-3 footer-title">{t("PRODUCTS")}</p>
                        <p className=" mb-3"><a className="footer-a" href="/AI-Listing-Creation" aria-label="AI Listing Creation">{t("AI Listing Creation")}</a></p>
                        <p className=" mb-3"><a className="footer-a" href="/Generate" aria-label="Generate">{t("Generate")}</a></p>
                        <p className=" mb-3"><a className="footer-a" href="/Enhance" aria-label="Enhance">{t("Enhance")}</a></p>
                        <p className=" mb-3"><a className="footer-a" href="/Understand" aria-label="Understand">{t("Understand")}</a></p>
                        <p className=" mb-3"><a className="footer-a" href="/Moderate" aria-label="Moderate">{t("Moderate")}</a></p>
                    </div>
                    <div className="col-md-3 footer-center text-left">
                        <p className="mb-3 footer-title">{t("SOLUTIONS")}</p>
                        <p className=" mb-3"><a className="footer-a" href="/Real-Estate-Agencies" aria-label="Real Estate Agencies">{t("Real Estate Agencies")}</a></p>
                        <p className=" mb-3"><a className="footer-a" href="/Marketplace" aria-label="Marketplaces">{t("Marketplaces")}</a></p>
                        <p className=" mb-3"><a className="footer-a" href="/Developers" aria-label="Real Estate Developers">{t("Real Estate Developers")}</a></p>
                        <p className=" mb-3"><a className="footer-a" href="/Interior-Designers" aria-label="Interior Designers">{t("Interior Designers")}</a></p>

                    </div>
                    <div className="col-md-3 footer-center text-left">
                        <p className="mb-3 footer-title">{t("COMPANY")}</p>
                        <p className=" mb-3"><a className="footer-a" href="#about-Us"> {t("About us")}</a></p>
                        <p className=" mb-3"><a className="footer-a" href="#Blogs"> {t("Blogs")}</a></p>
                        <p className=" mb-3"><a className="footer-a" href="#Terms"> {t("Terms and Conditions")}</a></p>
                        <p className=" mb-3"><a className="footer-a" href="#Privacy-Notice">{t("Privacy Notice")}</a></p>
                    </div>
                </div>
                {/* <div className="copyright">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <p className="p-small statement">{t("© 2024 Coralytics copy right")}</p>
                        </div>
                    </div>
                </div> */}
            </div >
        </div>

    </>
    )

}
export default Footer;