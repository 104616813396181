import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";
import FormContact from '../Contact Us/form-contact';
import { useTranslation } from 'react-i18next';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const GetStarted: React.FunctionComponent<{ Style: any, Value: any }> = (props) => {

    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (<>
        <button className={props.Style} onClick={handleClickOpen} >{props.Value}</button>

        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="xl"
            className='basic-Benefits' >

            <DialogTitle sx={{ m: 0, p: 4 }} id="customized-dialog-title">
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <div className="row  align-items-center" >
                    <div className="col-lg-6 col-xl-6 card-order-1">
                        <div className="image-container">
                            <img className="img-fluid-1 img-contact" src="/assets/images/image 2418.png" alt="alternative" />
                        </div>
                    </div>
                    <div className="col-lg-6 dir-rtl col-xl-6  card-order-2">
                        <FormContact />
                    </div>
                </div>
            </DialogContent>
        </BootstrapDialog>
    </>
    )

}
export default GetStarted;