import { useTranslation } from "react-i18next";
import Partners from "../home/partners";
import Section2 from "../home/section-2";
import AIListing from "../home/AI-listing";
import Section4 from "../home/section-4";
import VirtualStaging from "./virtual-staging";
import Portals from "../AI Listing/Portals";
import Section7 from "../home/section-7";
import Testimonials from "../AI Listing/Testimonials";
import Questions from "../AI Listing/Questions";
import Benefits from "./Benefits";
import Footer from "../home/footer";
import FormContact from "../Contact Us/form-contact";
import { Helmet } from "react-helmet-async";
import GetStarted from "../home/get-started";


const RealEstate: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };

    return (
        <>

            <Helmet>
                <title>{t("Helping Real Estate")}</title>
                <meta name="description" content={t("80+AI Capabilities to create")} />
            </Helmet>

            {/* section 1 */}
            <header id="header" className="header  page-home dir-rtl ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-container">
                                <h1 className="h1-large">{t("Helping Real Estate")}</h1>
                                <p className="p-large">{t("80+AI Capabilities to create")}</p>
                                <GetStarted Style="btn-solid-lg" Value={`${t("Get Your Free Demo")}`} />

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="image-container header-web padd-20">
                                <img className="img-fluid" src={"/assets/images/shes-found-the-perfect-home.png"} alt="alternative" />
                            </div>
                            <div className="image-container header-moblie">
                                <img className="img-fluid-1" src={"/assets/images/shes-found-the-perfect-home.png"} loading="lazy" alt="alternative" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <Partners />

            {/* Real estate agents and agencies rely on Coralytics*/}
            <Section2 />

            {/* AI Listing Creation */}
            <AIListing />

            <Section4 />

            {/* Generate Videos and 3D Floor plans and Image Enhancement and Virtual Staging*/}
            <VirtualStaging />

            <Portals />

            {/* Benefits */}
            <Benefits />

            {/*Boost your return on marketing */}
            <Section7 />

            <Testimonials />

            <Questions />

            <Footer />

          
        </>
    )

}
export default RealEstate;