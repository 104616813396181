import { useState } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';


const CompareSlider: React.FunctionComponent<{}> = (props) => {

    const [imgValue, setImgValue] = useState<number>(1)

    const handleChangeImg = (value: any) => {
        setImgValue(value)
    }

    return (
        <>
            <div className="image-container">
                <ReactCompareSlider
                    itemOne={<ReactCompareSliderImage src={`/assets/images/img-${imgValue}-1.png`}  alt="Image one" />}
                    itemTwo={<ReactCompareSliderImage src={`/assets/images/img-${imgValue}-2.png`} alt="Image two" />}
                />
                <div className="grid-container">
                    <div className="grid-item" onClick={() => handleChangeImg(1)}>
                        <img className="grid-img" src="/assets/images/img-1-2.png" alt="Image"/>
                    </div>
                    <div className="grid-item" onClick={() => handleChangeImg(2)}>
                        <img className="grid-img" src="/assets/images/img-2-2.png" alt="Image"/>
                    </div>
                    <div className="grid-item" onClick={() => handleChangeImg(3)}>
                        <img className="grid-img" src="/assets/images/img-3-2.png" alt="Image"/>
                    </div>
                    <div className="grid-item" onClick={() => handleChangeImg(4)}>
                        <img className="grid-img" src="/assets/images/img-4-2.png" alt="Image"/>
                    </div>
                    <div className="grid-item" onClick={() => handleChangeImg(5)}>
                        <img className="grid-img" src="/assets/images/img-5-2.png" alt="Image"/>
                    </div>
                </div>

            </div>

        </>
    )
}
export default CompareSlider;