import { useTranslation } from "react-i18next";
import Section4 from "../home/section-4";
import FormContact from "../Contact Us/form-contact";
import { Helmet } from "react-helmet-async";
import Section1 from "./section-1";
import Process from "./Process";
import Testimonials from "./Testimonials";
import GetStarted from "../home/get-started";


const Moderate: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();


    return (
        <>
            <Helmet>
                <title> {t("Moderate")}</title>
                <meta name="description" content={t("Elevate your developments with Coralytics")} />
            </Helmet>

            <header id="header" className="header page-generate page-enhance marg-5rem marg-under">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="text-container">
                                <div className="section-butt  d-flex justify-content-center">
                                    <span className="section-title-AI ">
                                        <svg width="24" height="25" className="marg10" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="mask0_135_2592" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                                                <path d="M24 0.679688H0V24.6797H24V0.679688Z" fill="white" />
                                            </mask>
                                            <g mask="url(#mask0_135_2592)">
                                                <path d="M8 16.6797C8 17.2319 8.44771 17.6797 9 17.6797C9.55229 17.6797 10 17.2319 10 16.6797C10 14.3713 10.5108 12.9373 11.3842 12.0639C12.2575 11.1905 13.6915 10.6797 16 10.6797C16.5522 10.6797 16.9999 10.232 16.9999 9.67969C16.9999 9.12741 16.5522 8.67969 16 8.67969C13.6915 8.67969 12.2575 8.16887 11.3842 7.2955C10.5108 6.42212 10 4.98818 10 2.67969C10 2.1274 9.55229 1.67969 9 1.67969C8.44771 1.67969 8 2.1274 8 2.67969C8 4.98818 7.48919 6.42212 6.6158 7.2955C5.74243 8.16887 4.30848 8.67969 2 8.67969C1.44772 8.67969 1 9.12741 1 9.67969C1 10.232 1.44772 10.6797 2 10.6797C4.30848 10.6797 5.74243 11.1905 6.6158 12.0639C7.48919 12.9373 8 14.3713 8 16.6797Z" fill="#573CFF" />
                                                <path d="M16.5 22.6796C16.5 23.232 16.9477 23.6797 17.5 23.6797C18.0523 23.6797 18.5 23.232 18.5 22.6796C18.5 21.2393 18.8198 20.4304 19.2852 19.9649C19.7507 19.4995 20.5596 19.1797 22 19.1797C22.5523 19.1797 23 18.732 23 18.1796C23 17.6274 22.5523 17.1797 22 17.1797C20.5596 17.1797 19.7507 16.8599 19.2852 16.3944C18.8198 15.929 18.5 15.1201 18.5 13.6796C18.5 13.1274 18.0523 12.6797 17.5 12.6797C16.9477 12.6797 16.5 13.1274 16.5 13.6796C16.5 15.1201 16.1802 15.929 15.7147 16.3944C15.2494 16.8599 14.4404 17.1797 13 17.1797C12.4477 17.1797 12 17.6274 12 18.1796C12 18.732 12.4477 19.1797 13 19.1797C14.4404 19.1797 15.2494 19.4995 15.7147 19.9649C16.1802 20.4304 16.5 21.2393 16.5 22.6796Z" fill="#573CFF" />
                                            </g>
                                        </svg>
                                        {t("Moderate")}
                                    </span>
                                </div>
                                <h1 className="h1-large text-center">{t("Moderate- Automate 100% of Your Listing Moderation")}</h1>
                                <h2 className="enh-h2">{t("Detect duplicates, remove fakes")}</h2>
                                <p className="p-large text-center">{t("Streamline your marketplace. Protect your brand")} </p>
                                <div className="d-flex justify-content-center mb-4">
                                    <GetStarted Style="btn-solid-lg" Value={`${t("Request a Demo")}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-12 d-flex justify-content-center ">
                            <img className="img-g borderRad" src={"/assets/images/image-578.jpg"}  alt="alternative"/>
                            <img className="img-g-mobile borderRad" src={"/assets/images/image-578.jpg"}  alt="alternative"  />

                        </div>
                    </div>
                </div>
            </header>

            {/*Why Automate Moderation with CORA?*/}
            <div className="basic-Benefits ">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <div className="section-butt">
                                <span className="section-title">
                                    <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                        <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                    </svg>{t("Benefits")}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1" style={{ marginBottom: "20px" }}>{t("Why Automate Moderation")}<span> {t("with Coralytics?")} </span> </h2>
                            <p className="p-heading-1 marg-b-30">{t("Ensure every property meets your")}</p>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <img src="/assets/images/image 2422.png" className="height-64" alt="" />
                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Save Time")} </div>
                                    <p className="testimonial-text"> {t("Automate up to 100% of listing moderation")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <img src="/assets/images/image 2424.png" className="height-64" alt="" />
                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Boost Buyer Trust")} </div>
                                    <p className="testimonial-text"> {t("Present a clean, high-quality marketplace")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <img src="/assets/images/image 2425.png" className="height-64" alt="" />

                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Enhance User Experience")} </div>
                                    <p className="testimonial-text"> {t("Provide buyers with a seamless browsing experience")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <img src="/assets/images/image 2426.png" className="height-64" alt="" />
                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Protect Your Brand")} </div>
                                    <p className="testimonial-text"> {t("Eliminate non-compliant listings and maintain")} </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Section1 />

            {/* Benefits for Real Estate Agencies*/}
            <div className="basic-Benefits ">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <div className="section-butt">
                                <span className="section-title">
                                    <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                        <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                    </svg>{t("Benefits")}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1" style={{ marginBottom: "20px" }}>{t("Benefits for Real")}<span> {t("Estate Agencies")} </span> </h2>
                            <p className="p-heading-1 marg-b-30">{t("Protect your brand and streamline")}</p>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <img src="/assets/images/image 2427.png" alt="" className="height-64" />
                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Remove Fake Listings")} </div>
                                    <p className="testimonial-text"> {t("Instantly flag and remove listings with misleading")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <img src="/assets/images/Group 1171274766.png" alt="" className="height-64" />
                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Ensure Compliance")} </div>
                                    <p className="testimonial-text"> {t("Automated checks for local and regional")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <img src="/assets/images/image 2429.png" alt="" className="height-64" />

                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Increase Exposure")} </div>
                                    <p className="testimonial-text"> {t("High-quality, accurate listings improve")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="card-benif d-flex justify-content-start">
                                <span>
                                    <img src="/assets/images/image 2430.png" alt=""  className="height-64"/>
                                </span>
                                <div>
                                    <div className="testimonial-author"> {t("Save Time and Resources")} </div>
                                    <p className="testimonial-text"> {t("Let AI handle the moderation, so your team")} </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Key Features of */}
            <div className="basic-Benefits ">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <div className="section-butt">
                                <span className="section-title">
                                    <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                        <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                    </svg>{t("Features")}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1" >{t("Key Features of")}<span> {t("Coralytics Moderate")} </span> </h2>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-4 mb-3">
                            <div className="card-features ">
                                <div className="d-flex align-items-center">
                                    <img src="/assets/images/image 2431.png" alt="" className="height-44"/>
                                    <h1 className="testimonial-author" style={{ marginBottom: "0px" }}>
                                        {t("Duplicate Detection")} </h1>
                                </div>
                                <p className="testimonial-text"> {t("Instantly identify and remove duplicate")} </p>

                                <h2>{t("How It Works")}</h2>
                                <p className="testimonial-text">{t("Coralytics scans listings using AI to detect duplicates")}</p>

                                <h2>{t("Benefit")}</h2>
                                <p className="testimonial-text">{t("Prevents buyer confusion and boosts")}</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card-features ">
                                <div className="d-flex align-items-center">
                                    <img src="/assets/images/image 2432.png" alt=""  className="height-44"/>
                                    <h1 className="testimonial-author" style={{ marginBottom: "0px" }}>
                                        {t("Fake Listing Detection")} </h1>
                                </div>
                                <p className="testimonial-text"> {t("Automatically flag listings that")} </p>

                                <h2>{t("How It Works")}</h2>
                                <p className="testimonial-text">{t("Coralytics algorithms analyze images, descriptions")}</p>

                                <h2>{t("Benefit")}</h2>
                                <p className="testimonial-text">{t("Protects your platform’s integrity and increases")}</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card-features ">
                                <div className="d-flex align-items-center">
                                    <img src="/assets/images/image 2433.png" alt="" className="height-44"/>
                                    <h1 className="testimonial-author" style={{ marginBottom: "0px" }}>
                                        {t("Quality Guidelines Adherence")} </h1>
                                </div>

                                <p className="testimonial-text"> {t("Ensure every listing aligns with your")} </p>

                                <h2>{t("How It Works")}</h2>
                                <p className="testimonial-text">{t("coralytics reviews listings for specific")}</p>

                                <h2>{t("Benefit")}</h2>
                                <p className="testimonial-text">{t("Maintains a high standard of listings")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Process />

            <Section4 />

            <Testimonials />

            <div className='basic-12 marg-2rem back-basic-1223'>
                <div className="container">
                    <div className="card">
                        <div className='row d-flex justify-content-center align-items-center align-self-center'>
                            <div className='col-lg-12 col-xl-12 '>
                                <h1> {t("Ready to Automate Your Moderation?")} </h1>
                                <p> {t("Coralytics moderation technology streamlines")}</p>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-auto col-12 d-flex justify-content-center mb-2 mb-md-0">

                                        <GetStarted Style="btn-solid-lg btn-solid-lg-color" Value={`${t("Request a Demo")}`} />
                                        <GetStarted Style="btn-solid-lg" Value={`${t("Contact Us")}`} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )

}
export default Moderate;