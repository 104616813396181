import { useTranslation } from "react-i18next";
import Section2 from "../home/section-2";
import Section4 from "../home/section-4";


const Features: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };

    return (
        <>


            {/* Image Analysis */}
            <div className="basic-Benefits marg-2rem">
                <div className="container padd-50">
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <div className="section-butt">
                                <span className="section-title">
                                    <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                        <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                    </svg>{t("Features")}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1" style={{ marginBottom: "0px" }}>{t("Powerful Features to")}<span> {t("Empower Your Business")} </span> </h2>
                            <h3>{t("Image Analysis")}</h3>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/Features-1.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Image Quality Score")} </div>
                                    <p className="testimonial-text"> {t("Automatically assess the quality")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/Features-2.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Brightness Score")} </div>
                                    <p className="testimonial-text"> {t("Evaluate the lighting in your photos")} </p>

                                </div>
                            </div></div>
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body flex-grow-1">
                                    <img className="quotes" src="/assets/images/Features-3.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Room Type Recognition")} </div>
                                    <p className="testimonial-text"> {t("Identify room types")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/Features-4.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Property View Classification")} </div>
                                    <p className="testimonial-text"> {t("Determine whether images are interior")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/Frame 2147224986.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Watermark Detection")} </div>
                                    <p className="testimonial-text"> {t("Detect and remove watermarks")} </p>

                                </div>
                            </div></div>
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body flex-grow-1">
                                    <img className="quotes" src={"/assets/images/Features-6.png"} alt="alternative" />
                                    <div className="testimonial-author"> {t("Image Tags for Enhanced SEO")} </div>
                                    <p className="testimonial-text"> {t("Generate relevant keywords and tags")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body flex-grow-1">
                                    <img className="quotes" src="/assets/images/Features-7.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Image Compliance Checks")} </div>
                                    <p className="testimonial-text"> {t("Ensure all images are appropriate")} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Text Analysis*/}
            <div className="basic-Benefits ">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1">{t("Text")}<span> {t("Analysis")} </span> </h2>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-4 mb-3">
                            <div className="card height-text">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/Features-8.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Review and NPS Survey Analysis")} </div>
                                    <p className="testimonial-text"> {t("Analyze customer reviews and Net Promoter")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-text">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/Features-9.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Sentiment Analysis")} </div>
                                    <p className="testimonial-text"> {t("Understand customer emotions")} </p>

                                </div>
                            </div></div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-text">
                                <div className="card-body flex-grow-1">
                                    <img className="quotes" src="/assets/images/Features-10.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Topic Extraction")} </div>
                                    <p className="testimonial-text"> {t("Identify recurring themes and topics")} </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Document Information Extraction*/}
            <div className="basic-Benefits ">
                <div className="container padd-50 padd-20-bottom">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1">{t("Document Information")}<span> {t("Extraction")} </span> </h2>
                        </div>
                    </div>
                    <div className="row dir-rtl">
                        <div className="col-md-4 mb-3">
                            <div className="card height-document">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/Features-11.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Data Extraction from Documents")} </div>
                                    <p className="testimonial-text"> {t("Automatically extract key information")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-document">
                                <div className="card-body">
                                    <img className="quotes" src="/assets/images/Features-12.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Passport and ID Recognition")} </div>
                                    <p className="testimonial-text"> {t("Extract and verify information from passports")} </p>

                                </div>
                            </div></div>
                        <div className="col-md-4 mb-3">
                            <div className="card height-document">
                                <div className="card-body flex-grow-1">
                                    <img className="quotes" src="/assets/images/Features-13.png" alt="alternative" />
                                    <div className="testimonial-author"> {t("Optical Character Recognition")} </div>
                                    <p className="testimonial-text"> {t("Convert scanned documents and images")} </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}
export default Features;