import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Newsletter from "./newsletter";
import ContactSection from "./ContactSection";

const Blogs: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };


    return (
        <>
            <Helmet>
                <title>{t("Blogs")}</title>
                <meta name="description" content={t("Welcome to Coralytics Blogs")} />
            </Helmet>

            {/* section 1 */}
            <header id="header" className="header page-blog dir-rtl ">
                <div className="container">
                    <div className="row padd-page">
                        <div className="col-md-12">
                            <div className="text-container">
                                <h1 className="h1-large text-center">{t("Blogs")}</h1>
                                <p className="p-large text-center">{t("Welcome to Coralytics Blogs")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row padd-page">
                        <div className="col-md-4 mb-3">
                            <span className="card-text">
                                <h2 className="tag">{t("Suggestions")}</h2>
                            </span>
                        </div>
                        <div className="col-md-4 mb-3">
                            <span className="card-text">
                                <h2 className="tag">{t("Latest Articles")}</h2>
                            </span>
                        </div>
                        <div className="col-md-4 mb-3">
                            <span className="card-text">
                                <h2 className="tag">{t("Popular Articles")}</h2>
                            </span>
                        </div>
                    </div>
                    <div className="row mt-3 card-search">
                        <div className="col-10">
                            <input
                                type="text" id="search"
                                className="form-control-input"
                                placeholder={t("Search blogs")}
                            />
                        </div>
                        <div className="col-2">
                            <button className="button-search"> {t("Search")}</button>
                        </div>
                    </div>

                </div>
            </header>

            <div className="cards-blogs  dir-rtl">
                <div className="container">
                    <div className="row">
                            <div className="d-flex justify-content-between  align-items-center" >
                                <div className="text-start">
                                <h2 className="latest-articles"> {t("Latest Articles")}</h2>
                                </div>
                                <div className="writer text-end"> {t("Read all articles")}</div>
                            </div>
                    </div>
                    <div className="row dir-rtl mt-4">
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-1.webp")} alt="alternative" />

                                <div className="title"> {t("blog title 1")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 1")} </p>
                                {/* <a className="btn-solid-reg" href="/boosting-real-estate-confidence-battling-fake-listings-with-AI">{t("Learn More")}</a> */}
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-2.webp")} alt="alternative" />
                                <div className="title"> {t("blog title 2")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 2")} </p>

                                {/* <a className="btn-solid-reg" href="/how-AI-is-fixing-real-estate-marketplace-flows">{t("Learn More")}  </a> */}
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-3.webp")} alt="alternative" />

                                <div className="title"> {t("blog title 3")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 3")} </p>

                                {/* <a className="btn-solid-reg" href="/enhancing-the-visuals-with-AI-A-revolutionary-approach-to-real-estate-marketing">{t("Learn More")}  </a> */}
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-4.webp")} alt="alternative" />
                                <div className="title"> {t("blog title 4")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 4")} </p>

                                {/* <a className="btn-solid-reg" href="/AI-powered-room-staging-A-game-changer-for-real-estate">{t("Learn More")}  </a> */}
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-5.webp")} alt="alternative" />

                                <div className="title"> {t("blog title 5")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 5")} </p>

                                {/* <a className="btn-solid-reg" href="/the-power-of-listing-videos-boosting-conversions-for-real-estate-agents">{t("Learn More")}  </a> */}
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-6.webp")} alt="alternative" />
                                <div className="title"> {t("blog title 6")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 6")} </p>

                                {/* <a className="btn-solid-reg" href="/list-properties-quicker-with-AI-property-description">{t("Learn More")}  </a> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cards-blogs dir-rtl">
                <div className="container">
                    <div className="row">
                            <div className="d-flex justify-content-between  align-items-center" >
                                <div className="text-start">
                                <h2 className="latest-articles"> {t("Popular Articles")}</h2>
                                </div>
                                <div className="writer text-end"> {t("Read all articles")}</div>
                            </div>
                    </div>
                    <div className="row dir-rtl mt-4">
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-1.webp")} alt="alternative" />

                                <div className="title"> {t("blog title 1")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 1")} </p>
                                {/* <a className="btn-solid-reg" href="/boosting-real-estate-confidence-battling-fake-listings-with-AI">{t("Learn More")}</a> */}
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-2.webp")} alt="alternative" />
                                <div className="title"> {t("blog title 2")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 2")} </p>

                                {/* <a className="btn-solid-reg" href="/how-AI-is-fixing-real-estate-marketplace-flows">{t("Learn More")}  </a> */}
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-3.webp")} alt="alternative" />

                                <div className="title"> {t("blog title 3")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 3")} </p>

                                {/* <a className="btn-solid-reg" href="/enhancing-the-visuals-with-AI-A-revolutionary-approach-to-real-estate-marketing">{t("Learn More")}  </a> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="cards-blogs  dir-rtl">
                <div className="container">
                    <div className="row">
                            <div className="d-flex justify-content-between  align-items-center" >
                                <div className="text-start">
                                <h2 className="latest-articles"> {t("Real Estate Tips")}</h2>
                                </div>
                                <div className="writer text-end"> {t("Read all articles")}</div>
                            </div>
                    </div>
                    <div className="row dir-rtl mt-4">
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-1.webp")} alt="alternative" />

                                <div className="title"> {t("blog title 1")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 1")} </p>
                                {/* <a className="btn-solid-reg" href="/boosting-real-estate-confidence-battling-fake-listings-with-AI">{t("Learn More")}</a> */}
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-2.webp")} alt="alternative" />
                                <div className="title"> {t("blog title 2")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 2")} </p>

                                {/* <a className="btn-solid-reg" href="/how-AI-is-fixing-real-estate-marketplace-flows">{t("Learn More")}  </a> */}
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="card-body blog">
                                <span className="bd-highlight" > {t("11 March 2024")}</span>
                                <img className="cover-blog" src={getImagePath("blog-3.webp")} alt="alternative" />

                                <div className="title"> {t("blog title 3")} </div>
                                <div className="d-flex justify-content-between padd-r-l">
                                    <div className="writer text-start">{t("Industry")}:{t("Real Estate")}</div>
                                    <div className="writer text-end"> {t("Writer")}:{t("Real Estate")}</div>
                                </div>
                                <p className="parag"> {t("blog desc 3")} </p>

                                {/* <a className="btn-solid-reg" href="/enhancing-the-visuals-with-AI-A-revolutionary-approach-to-real-estate-marketing">{t("Learn More")}  </a> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <ContactSection/>
            
            <Newsletter/>
        </>
    )
}
export default Blogs;