import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';


const Testimonials: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const getImagePath = (fileName: any) => {
        return `/assets/images/${i18n.language}/${fileName}`;
    };

    return (
        <>

            {/* Testimonials */}
            <div className="cards-2 ">
                <div className="container padd-50">
                    <div className="row mb-4" >
                        <div className="col-lg-12">
                            <div className="section-butt  d-flex justify-content-center">
                                <span className="section-title">
                                    <svg width="22" height="22" className="marg10" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 15C7 15.5522 7.44771 16 8 16C8.55229 16 9 15.5522 9 15C9 12.6916 9.51081 11.2576 10.3842 10.3842C11.2575 9.51081 12.6915 9 15 9C15.5522 9 15.9999 8.55228 15.9999 8C15.9999 7.44772 15.5522 7 15 7C12.6915 7 11.2575 6.48918 10.3842 5.61581C9.51081 4.74243 9 3.30849 9 1C9 0.447713 8.55229 0 8 0C7.44771 0 7 0.447713 7 1C7 3.30849 6.48919 4.74243 5.6158 5.61581C4.74243 6.48918 3.30848 7 1 7C0.447717 7 0 7.44772 0 8C0 8.55228 0.447717 9 1 9C3.30848 9 4.74243 9.51081 5.6158 10.3842C6.48919 11.2576 7 12.6916 7 15Z" fill="#573CFF" />
                                        <path d="M15.5 21C15.5 21.5523 15.9477 22 16.5 22C17.0523 22 17.5 21.5523 17.5 21C17.5 19.5596 17.8198 18.7507 18.2852 18.2852C18.7507 17.8198 19.5596 17.5 21 17.5C21.5523 17.5 22 17.0523 22 16.5C22 15.9477 21.5523 15.5 21 15.5C19.5596 15.5 18.7507 15.1802 18.2852 14.7147C17.8198 14.2494 17.5 13.4404 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 13.4404 15.1802 14.2494 14.7147 14.7147C14.2494 15.1802 13.4404 15.5 12 15.5C11.4477 15.5 11 15.9477 11 16.5C11 17.0523 11.4477 17.5 12 17.5C13.4404 17.5 14.2494 17.8198 14.7147 18.2852C15.1802 18.7507 15.5 19.5596 15.5 21Z" fill="#573CFF" />
                                    </svg>{t("Testimonials")}
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1" style={{marginTop:"0px"}}>{t("Success")}<span> {t("Stories")} </span></h2>
                        </div>
                    </div>
                    <div className="">
                        <Swiper
                            cssMode={true}
                            navigation={true}
                            pagination={true}
                            mousewheel={true}
                            keyboard={true}
                            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='row '>
                                    <div className='col-md-12 mb-3 mt-3 testimonials-enhance'>
                                        <div className="card ">
                                            <div className='profile'>
                                                <img src="/assets/images/user-2.png" alt="user"/>
                                                <div>
                                                    <h1>{t("Sophia L")}<span>, {t("Project Manager")}</span> </h1>
                                                </div>
                                                <span className='d-flex justify-content-center'>
                                                    <svg width="140" height="30" viewBox="0 0 140 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.5489 4.23564C13.8483 3.31433 15.1517 3.31433 15.4511 4.23564L17.4187 10.2914C17.5526 10.7034 17.9365 10.9824 18.3697 10.9824H24.7371C25.7058 10.9824 26.1086 12.222 25.3249 12.7914L20.1736 16.534C19.8231 16.7887 19.6764 17.24 19.8103 17.6521L21.7779 23.7078C22.0773 24.6291 21.0228 25.3952 20.2391 24.8258L15.0878 21.0832C14.7373 20.8285 14.2627 20.8285 13.9122 21.0832L8.7609 24.8258C7.97719 25.3952 6.92271 24.6291 7.22206 23.7078L9.18969 17.6521C9.32356 17.24 9.1769 16.7887 8.82642 16.534L3.67511 12.7914C2.89139 12.222 3.29417 10.9824 4.26289 10.9824H10.6303C11.0635 10.9824 11.4474 10.7034 11.5813 10.2914L13.5489 4.23564Z" fill="#FFA722" />
                                                        <path d="M69.0457 4.35427C69.3386 3.41958 70.6614 3.41958 70.9543 4.35427L72.8113 10.2813C72.942 10.6985 73.3284 10.9824 73.7656 10.9824H79.8503C80.8101 10.9824 81.2187 12.2034 80.452 12.781L75.4676 16.5366C75.1298 16.7911 74.9886 17.2306 75.1151 17.6342L77.0041 23.6633C77.2948 24.591 76.2245 25.346 75.4481 24.761L70.6018 21.1095C70.2455 20.8411 69.7545 20.8411 69.3982 21.1095L64.5519 24.761C63.7755 25.346 62.7052 24.5911 62.9959 23.6633L64.8849 17.6342C65.0114 17.2306 64.8702 16.7911 64.5324 16.5366L59.548 12.781C58.7813 12.2034 59.1899 10.9824 60.1497 10.9824H66.2344C66.6716 10.9824 67.058 10.6985 67.1887 10.2813L69.0457 4.35427Z" fill="#FFA722" />
                                                        <path d="M125.5 4.54466L127.468 10.6004C127.735 11.4244 128.503 11.9824 129.37 11.9824H135.737L130.586 15.725C129.885 16.2343 129.592 17.137 129.859 17.9611L131.827 24.0168L126.676 20.2742C125.975 19.7649 125.025 19.7649 124.324 20.2742L119.173 24.0168L121.141 17.9611C121.408 17.137 121.115 16.2343 120.414 15.725L115.263 11.9824L121.63 11.9824C122.497 11.9824 123.265 11.4244 123.532 10.6004L125.5 4.54466Z" stroke="#FFA722" strokeWidth="2" />
                                                        <path d="M41.5489 4.23564C41.8483 3.31433 43.1517 3.31433 43.4511 4.23564L45.4187 10.2914C45.5526 10.7034 45.9365 10.9824 46.3697 10.9824H52.7371C53.7058 10.9824 54.1086 12.222 53.3249 12.7914L48.1736 16.534C47.8231 16.7887 47.6764 17.24 47.8103 17.6521L49.7779 23.7078C50.0773 24.6291 49.0228 25.3952 48.2391 24.8258L43.0878 21.0832C42.7373 20.8285 42.2627 20.8285 41.9122 21.0832L36.7609 24.8258C35.9772 25.3952 34.9227 24.6291 35.2221 23.7078L37.1897 17.6521C37.3236 17.24 37.1769 16.7887 36.8264 16.534L31.6751 12.7914C30.8914 12.222 31.2942 10.9824 32.2629 10.9824H38.6303C39.0635 10.9824 39.4474 10.7034 39.5813 10.2914L41.5489 4.23564Z" fill="#FFA722" />
                                                        <path d="M96.5489 4.23564C96.8483 3.31433 98.1517 3.31433 98.4511 4.23564L100.419 10.2914C100.553 10.7034 100.937 10.9824 101.37 10.9824H107.737C108.706 10.9824 109.109 12.222 108.325 12.7914L103.174 16.534C102.823 16.7887 102.676 17.24 102.81 17.6521L104.778 23.7078C105.077 24.6291 104.023 25.3952 103.239 24.8258L98.0878 21.0832C97.7373 20.8285 97.2627 20.8285 96.9122 21.0832L91.7609 24.8258C90.9772 25.3952 89.9227 24.6291 90.2221 23.7078L92.1897 17.6521C92.3236 17.24 92.1769 16.7887 91.8264 16.534L86.6751 12.7914C85.8914 12.222 86.2942 10.9824 87.2629 10.9824H93.6303C94.0635 10.9824 94.4474 10.7034 94.5813 10.2914L96.5489 4.23564Z" fill="#FFA722" />
                                                        <mask id="mask0_221_28338" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="114" y="3" width="23" height="22">
                                                            <path d="M124.539 3.8626C124.839 2.94129 126.142 2.94129 126.441 3.8626L128.411 9.92515C128.545 10.3372 128.929 10.6161 129.362 10.6161H135.737C136.706 10.6161 137.108 11.8558 136.325 12.4252L131.168 16.172C130.817 16.4267 130.67 16.878 130.804 17.2901L132.774 23.3526C133.073 24.2739 132.019 25.04 131.235 24.4706L126.078 20.7238C125.728 20.4691 125.253 20.4691 124.903 20.7238L119.745 24.4706C118.962 25.04 117.907 24.2739 118.207 23.3526L120.176 17.2901C120.31 16.878 120.164 16.4267 119.813 16.172L114.656 12.4252C113.872 11.8558 114.275 10.6161 115.244 10.6161H121.618C122.052 10.6161 122.436 10.3372 122.569 9.92515L124.539 3.8626Z" fill="#FFA722" />
                                                        </mask>
                                                        <g mask="url(#mask0_221_28338)">
                                                            <rect x="114.227" y="1.48438" width="10.9881" height="25.2727" rx="1" fill="#FFA722" />
                                                        </g>
                                                    </svg>

                                                </span>
                                            </div>
                                            <p>
                                                "{t("Coralytics has transformed our marketing approach")}"
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='row '>
                                    <div className='col-md-12 mb-3 mt-3 testimonials-enhance'>
                                        <div className="card ">
                                            <div className='profile'>
                                                <img src="/assets/images/user-2.png" alt="user"/>
                                                <div>
                                                    <h1>{t("Sophia L")}<span>, {t("Project Manager")}</span> </h1>
                                                </div>
                                                <span className='d-flex justify-content-center'>
                                                    <svg width="140" height="30" viewBox="0 0 140 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.5489 4.23564C13.8483 3.31433 15.1517 3.31433 15.4511 4.23564L17.4187 10.2914C17.5526 10.7034 17.9365 10.9824 18.3697 10.9824H24.7371C25.7058 10.9824 26.1086 12.222 25.3249 12.7914L20.1736 16.534C19.8231 16.7887 19.6764 17.24 19.8103 17.6521L21.7779 23.7078C22.0773 24.6291 21.0228 25.3952 20.2391 24.8258L15.0878 21.0832C14.7373 20.8285 14.2627 20.8285 13.9122 21.0832L8.7609 24.8258C7.97719 25.3952 6.92271 24.6291 7.22206 23.7078L9.18969 17.6521C9.32356 17.24 9.1769 16.7887 8.82642 16.534L3.67511 12.7914C2.89139 12.222 3.29417 10.9824 4.26289 10.9824H10.6303C11.0635 10.9824 11.4474 10.7034 11.5813 10.2914L13.5489 4.23564Z" fill="#FFA722" />
                                                        <path d="M69.0457 4.35427C69.3386 3.41958 70.6614 3.41958 70.9543 4.35427L72.8113 10.2813C72.942 10.6985 73.3284 10.9824 73.7656 10.9824H79.8503C80.8101 10.9824 81.2187 12.2034 80.452 12.781L75.4676 16.5366C75.1298 16.7911 74.9886 17.2306 75.1151 17.6342L77.0041 23.6633C77.2948 24.591 76.2245 25.346 75.4481 24.761L70.6018 21.1095C70.2455 20.8411 69.7545 20.8411 69.3982 21.1095L64.5519 24.761C63.7755 25.346 62.7052 24.5911 62.9959 23.6633L64.8849 17.6342C65.0114 17.2306 64.8702 16.7911 64.5324 16.5366L59.548 12.781C58.7813 12.2034 59.1899 10.9824 60.1497 10.9824H66.2344C66.6716 10.9824 67.058 10.6985 67.1887 10.2813L69.0457 4.35427Z" fill="#FFA722" />
                                                        <path d="M125.5 4.54466L127.468 10.6004C127.735 11.4244 128.503 11.9824 129.37 11.9824H135.737L130.586 15.725C129.885 16.2343 129.592 17.137 129.859 17.9611L131.827 24.0168L126.676 20.2742C125.975 19.7649 125.025 19.7649 124.324 20.2742L119.173 24.0168L121.141 17.9611C121.408 17.137 121.115 16.2343 120.414 15.725L115.263 11.9824L121.63 11.9824C122.497 11.9824 123.265 11.4244 123.532 10.6004L125.5 4.54466Z" stroke="#FFA722" strokeWidth="2" />
                                                        <path d="M41.5489 4.23564C41.8483 3.31433 43.1517 3.31433 43.4511 4.23564L45.4187 10.2914C45.5526 10.7034 45.9365 10.9824 46.3697 10.9824H52.7371C53.7058 10.9824 54.1086 12.222 53.3249 12.7914L48.1736 16.534C47.8231 16.7887 47.6764 17.24 47.8103 17.6521L49.7779 23.7078C50.0773 24.6291 49.0228 25.3952 48.2391 24.8258L43.0878 21.0832C42.7373 20.8285 42.2627 20.8285 41.9122 21.0832L36.7609 24.8258C35.9772 25.3952 34.9227 24.6291 35.2221 23.7078L37.1897 17.6521C37.3236 17.24 37.1769 16.7887 36.8264 16.534L31.6751 12.7914C30.8914 12.222 31.2942 10.9824 32.2629 10.9824H38.6303C39.0635 10.9824 39.4474 10.7034 39.5813 10.2914L41.5489 4.23564Z" fill="#FFA722" />
                                                        <path d="M96.5489 4.23564C96.8483 3.31433 98.1517 3.31433 98.4511 4.23564L100.419 10.2914C100.553 10.7034 100.937 10.9824 101.37 10.9824H107.737C108.706 10.9824 109.109 12.222 108.325 12.7914L103.174 16.534C102.823 16.7887 102.676 17.24 102.81 17.6521L104.778 23.7078C105.077 24.6291 104.023 25.3952 103.239 24.8258L98.0878 21.0832C97.7373 20.8285 97.2627 20.8285 96.9122 21.0832L91.7609 24.8258C90.9772 25.3952 89.9227 24.6291 90.2221 23.7078L92.1897 17.6521C92.3236 17.24 92.1769 16.7887 91.8264 16.534L86.6751 12.7914C85.8914 12.222 86.2942 10.9824 87.2629 10.9824H93.6303C94.0635 10.9824 94.4474 10.7034 94.5813 10.2914L96.5489 4.23564Z" fill="#FFA722" />
                                                        <mask id="mask0_221_28338" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="114" y="3" width="23" height="22">
                                                            <path d="M124.539 3.8626C124.839 2.94129 126.142 2.94129 126.441 3.8626L128.411 9.92515C128.545 10.3372 128.929 10.6161 129.362 10.6161H135.737C136.706 10.6161 137.108 11.8558 136.325 12.4252L131.168 16.172C130.817 16.4267 130.67 16.878 130.804 17.2901L132.774 23.3526C133.073 24.2739 132.019 25.04 131.235 24.4706L126.078 20.7238C125.728 20.4691 125.253 20.4691 124.903 20.7238L119.745 24.4706C118.962 25.04 117.907 24.2739 118.207 23.3526L120.176 17.2901C120.31 16.878 120.164 16.4267 119.813 16.172L114.656 12.4252C113.872 11.8558 114.275 10.6161 115.244 10.6161H121.618C122.052 10.6161 122.436 10.3372 122.569 9.92515L124.539 3.8626Z" fill="#FFA722" />
                                                        </mask>
                                                        <g mask="url(#mask0_221_28338)">
                                                            <rect x="114.227" y="1.48438" width="10.9881" height="25.2727" rx="1" fill="#FFA722" />
                                                        </g>
                                                    </svg>

                                                </span>
                                            </div>
                                            <p>
                                                "{t("Coralytics has transformed our marketing approach")}"
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Testimonials;