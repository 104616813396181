import { useTranslation } from "react-i18next";


const Section2: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();

    return (
        <>
            {/* Real estate agents and agencies rely on Coralytics */}
            <div className="cards-2 col-width">
            <img className="decoration-circles-1" src="/assets/images/Group 50.svg" alt="alternative"/>
            <img className="decoration-circles-2" src="/assets/images/Group 49.svg" alt="alternative"/>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="h2-heading-1">{t("Real estate")}  <span> {t("agents")} </span> {t("and")} <span> {t("agencies")}</span> {t("rely")}</h2>
                        </div>
                    </div>
                    <div className="row marg-top-70">
                        <div className="col-3 card-order-1 padd-0">
                            <div className="card-agent-1 ">
                                <h5>
                                    90%
                                </h5>
                                <p>
                                    {t("of time saved")}
                                </p>
                            </div>
                        </div>
                        <div className="col-6 card-order-3 padd-0">
                            <img className="image" src="/assets/images/Group 33126.png" alt="alternative"/>
                        </div>
                        <div className="col-3 card-order-2 padd-0">
                            <div className="card-agent-1 ">
                                <h5>
                                    +40%
                                </h5>
                                <p>
                                    {t("More conversion with")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center" >
                        <div className="col-3 mt-4" >
                            <div className="card-agent-1" style={{ maxWidth: "100%" }}>
                                <h5 >
                                +83%
                                </h5>
                                <p>
                                    {t("Buyer Interest")}
                                </p>
                            </div>
                        </div>
                        <div className="col-3 mt-4" >
                            <div className="card-agent-1" style={{ maxWidth: "100%" }}>
                                <h5 >
                                2X
                                </h5>
                                <p>
                                    {t("users spend twice the time")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Section2;