import { Route, Routes } from 'react-router-dom';
import Layout from '../component/layout/layout';
import Home from '../component/home/home';
import { useEffect } from 'react';
import i18n from '../i18n';
import AIListingCreation from '../component/AI Listing/AI-Listing-creation';
import RealEstate from '../component/Real Estate Agencies/real-estate';
import Generate from '../component/Generate/Generate';
import Enhance from '../component/Enhance/Enhance';
import Understand from '../component/Understand/Understand';
import Marketplace from '../component/Marketplace/Marketplace';
import InteriorDesigners from '../component/Interior Designers/Interior Designers';
import ContactUs from '../component/Contact Us/Contact Us';
import Developers from '../component/Developers/Developers';
import Moderate from '../component/Moderate/moderate';
import Blogs from '../component/blogs/blogs';



const PublicRouter: React.FunctionComponent<{}> = (props) => {

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
            document.body.classList.toggle('rtl', savedLanguage === 'ar');
            document.body.classList.toggle('ltr', savedLanguage !== 'ar');
        }
    }, []);

    return (
        <>

            <Routes>
                <Route path='/' element={<Layout />} >
                    <Route path="" element={<Home />} />
                    <Route path="*" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/AI-Listing-Creation" element={<AIListingCreation />} />
                    <Route path="/Real-Estate-Agencies" element={<RealEstate />} />
                    <Route path="/Generate" element={<Generate />} />
                    <Route path="/Enhance" element={<Enhance />} />
                    <Route path="/Understand" element={<Understand />} />
                    <Route path="/Marketplace" element={<Marketplace />} />
                    <Route path="/Interior-Designers" element={<InteriorDesigners />} />
                    <Route path="/ContactUs" element={<ContactUs />} />
                    <Route path="/Developers" element={<Developers />} />
                    <Route path="/Moderate" element={<Moderate />} />
                    <Route path="/Blogs" element={<Blogs />} />
                </Route>
            </Routes>

        </>
    )
}
export default PublicRouter;